export const API = (options = {}) =>
	{
	let url;

	if (typeof(options) !== "object")
		{
		url = options;
		options = {};
		}
	else
		{
		url = options.url;
		delete(options.url);
		}

	if ("body" in options)
		options.body = JSON.stringify(options.body);

	if (!url.startsWith('/'))
		url = `/${url}`;
	url = `/api${url}`;
	//url = `http://localhost:3001/${url}`;

  return fetch(url,
		{
		credentials: 'include',
    headers: { 'Content-Type': `application/json` },
    ...options
		})
	.then(response =>
		response.json().then(json =>
			response.ok ? json : Promise.reject(
				{
				...json,
				status: response.status,
				statusText: response.statusText
				})
		))
  .catch(Promise.reject);
	};

export default API;
