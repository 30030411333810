import React from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';

import API from '../../utils/API';

export class LoginContainer extends React.Component
	{
	constructor(props)
		{
		super(props);
		this.state =
			{
			username: "",
			password: "",
			};
		}

	handle_login(event)
		{
		API(
			{
			url: '/aaa/login',
			method: 'POST',
			body:
				{
				username: this.state.username,
				password: this.state.password,
				},
			})
		.then(data =>
			{
			if (data.success)
				{
				this.props.setUser(this.state.username);
				this.props.history.push('/');
				}
			else
				alert("Nope.");
			});

		event.preventDefault();
		return false;
		}

	componentDidMount()
		{
		this.nameInput.focus();
		}

	render()
		{
		return(
			<Row>
				<Col xs={{ span: 12 }} sm={{ span: 10, offset: 1 }} md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }} xl={{ span: 4, offset: 4 }}>
					<Form onSubmit={ (event) => this.handle_login(event) }>
						<Form.Group>
							<Form.Label>Username</Form.Label>
							<Form.Control type="text" onChange={ (event) => this.setState({ username: event.target.value }) } ref={(input) => { this.nameInput = input; } } />
						</Form.Group>
						<Form.Group>
							<Form.Label>Password</Form.Label>
							<Form.Control type="password" onChange={ (event) => this.setState({ password: event.target.value }) }/>
						</Form.Group>
						<Button variant="primary" type="submit" block>Log In</Button>
					</Form>
				</Col>
			</Row>
		);
		};
	};

export default LoginContainer;
