import React from 'react';

import SortTable from '../../component/SortTable';
import EditDialogue from '../../component/EditDialogue';

import API from '../../utils/API';
import { Type, Sort } from '../../utils/Primer';

export class PrimerContainer extends React.Component
	{
	constructor(props)
		{
		super(props);
		this.state =
			{
			primers: null,
			primer: null,
			};
		}

	save = primer =>
		{
		API(
			{
			url: `/primer/${primer.id || ''}`,
			method: primer.id ? 'PATCH' : 'PUT',
			body: primer,
			})
		.then((data) =>
			{
			this.setState((state, props) =>
				{
				let newState = { ...state };
				const i = newState.primers.findIndex(e => e.id === primer.id);
				if (i >= 0)
					newState.primers[i] = primer;
				else if (!newState.primer.id && data.id)
					{
					newState.primer.id = data.id;
					newState.primers.push(primer);
					}
				newState.primer = null;

				return newState;
				});
			});
		}

	hide = () => this.setState({	primer: null });

	render()
		{
		var settings =
			{
			header_icons:
				[
					{
					icon: "plus",
					onClick: () =>
						{
						this.setState({ primer: {} });
						},
					},
				],
			icons:
				[
					{
					icon: "edit",
					onClick: (i) =>
						{
						this.setState({ primer: { ...this.state.primers[i] } });
						},
					},
				],
			sort: [ 1, 0, 2 ],
			group: 1,
			columns:
				[
					{
					name: "Manufacturer",
					key: "manufacturer",
					sort: (a, b) => b.manufacturer.localeCompare(a.manufacturer, undefined, { caseFirst: "upper" }),
					},
					{
					name: "Type",
					key: "primer_type",
					sort: Sort,
					display: Type,
					visible: false,
					},
					{
					name: "Name",
					key: "name",
					sort: (a, b) => b.name.localeCompare(a.name, undefined, { caseFirst: "upper" }),
					},
					{
					name: "Stock",
					key: "stock",
					},
				],
			};

		if (this.state.primers === null)
			{
			API("/primer").then((data) =>
				{
				this.setState({ primers: data.primers });
				});
			}

		const edit_layout = [
			{ name: "Manufacturer", value: "manufacturer", required: true },
			{ name: "Name", value: "name", required: true },
			{ name: "Type", value: "primer_type", required: true, options:
				[
				{ name: "Small Pistol", value: "SP" },
				{ name: "Small Pistol Magnum", value: "SPM" },
				{ name: "Large Pistol", value: "LP" },
				{ name: "Large Pistol Magnum", value: "LPM" },
				{ name: "Small Rifle", value: "SR" },
				{ name: "Small Rifle Magnum", value: "SRM" },
				{ name: "Large Rifle", value: "LR" },
				{ name: "Large Rifle Magnum", value: "LRM" },
				{ name: "Shotshell", value: "SS" },
				],
			},
			{ name: "Stock", value: "stock", type: "number", required: true },
		];

		return (
			<>
				<SortTable data={this.state.primers} {...settings} />
				{ this.state.primer !== null &&
					<EditDialogue
						layout={edit_layout}
						onHide={this.hide}
						title="Primer"
						item={this.state.primer}
						onSave={this.save}
					/>
				}
			</>
		);
		}
	}

export default PrimerContainer;
