import React from 'react';

import SortTable from '../../component/SortTable';
import EditDialogue from '../../component/EditDialogue';

import API from '../../utils/API';
import { GrainWhole } from '../../utils/Grain.js';
import { Caliber, BC } from '../../utils/Bullet';

export class BulletContainer extends React.Component
	{
	constructor(props)
		{
		super(props);
		this.state =
			{
			bullets: null,
			bullet: null,
			};

		this.hide = this.hide.bind(this);
		this.save = this.save.bind(this);
		}

	save(bullet)
		{
		API(
			{
			url: `/bullet/${bullet.id || ''}`,
			method: bullet.id ? 'PATCH' : 'PUT',
			body: bullet,
			})
		.then((data) =>
			{
			this.setState((state, props) =>
				{
				let newState = { ...state };
				const i = newState.bullets.findIndex(e => e.id === bullet.id);
				if (i >= 0)
					newState.bullets[i] = bullet;
				else if (!newState.bullet.id && data.id)
					{
					newState.bullet.id = data.id;
					newState.bullets.push(bullet);
					}
				newState.bullet = null;

				return newState;
				});
			});
		}

	hide()
		{
		this.setState({	bullet: null });
		}

	render()
		{
		var settings =
			{
			header_icons:
				[
					{
					icon: "plus",
					onClick: () =>
						{
						this.setState({ bullet: {} });
						},
					},
				],
			icons:
				[
					{
					icon: "edit",
					onClick: (i) =>
						{
						this.setState({ bullet: { ...this.state.bullets[i] }, });
						},
					},
				],
			sort: [ 1, 3, 0 ],
			columns:
				[
					{
					name: "Manufacturer",
					key: "manufacturer",
					sort: (a, b) => b.manufacturer.localeCompare(a.manufacturer, undefined, { caseFirst: "upper" }),
					},
					{
					name: "Caliber",
					key: "caliber",
					display: Caliber,
					},
					{
					name: "Line",
					key: "line",
					},
					{
					name: "Weight",
					key: "weight",
					display: GrainWhole,
					},
					{
					name: "BC",
					key: "bc",
					display: BC,
					},
					{
					name: "Type",
					key: "type",
					},
					{
					name: "Stock",
					key: "stock",
					},
				],
			};
		if (this.state.bullets === null)
			{
			API("/bullet").then((data) =>
				{
				this.setState({ bullets: data.bullets });
				});
			}

		const edit_layout = [
			{ name: "Manufacturer", value: "manufacturer" },
			{ name: "Line", value: "line" },
			{ name: "Type", value: "type" },
			[
				{ name: "Caliber", value: "caliber", append: '"' },
				{ name: "BC", value: "bc" },
			],
			[
				{ name: "Weight", value: "weight", append: "gr." },
				{ name: "Stock", value: "stock" },
			],
		];

		return (
			<>
				<SortTable data={this.state.bullets} {...settings} />
				{ this.state.bullet !== null &&
					<EditDialogue
						layout={edit_layout}
						onHide={this.hide}
						title="Bullet"
						item={this.state.bullet}
						onSave={this.save}
					/>
				}
			</>
		);
		}
	}

export default BulletContainer;
