import React from 'react';

import SortTable from '../../component/SortTable';
import EditDialogue from '../../component/EditDialogue';

import API from '../../utils/API';
import { Pound, toPound, toGrain } from '../../utils/Grain.js';

export class PowderContainer extends React.Component
	{
	constructor(props)
		{
		super(props);
		this.state =
			{
			powders: null,
			powder: null,
			};

		this.hide = this.hide.bind(this);
		this.save = this.save.bind(this);
		}

	save(powder)
		{
		API(
			{
			url: `/powder/${powder.id || ''}`,
			method: powder.id ? 'PATCH' : 'PUT',
			body: powder,
			})
		.then((data) =>
			{
			this.setState((state, props) =>
				{
				let newState = { ...state };
				const i = newState.powders.findIndex(e => e.id === powder.id);
				if (i >= 0)
					newState.powders[i] = powder;
				else if (!newState.powder.id && data.id)
					{
					newState.powder.id = data.id;
					newState.powders.push(powder);
					}
				newState.powder = null;

				return newState;
				});
			});
		}

	hide()
		{
		this.setState({	powder: null });
		}

	render()
		{
		var settings =
			{
			header_icons:
				[
					{
					icon: "plus",
					onClick: () =>
						{
						this.setState({ powder: {} });
						},
					},
				],
			icons:
				[
					{
					icon: "edit",
					onClick: (i) =>
						{
						this.setState({ powder: { ...this.state.powders[i] } });
						},
					},
				],
			sort: [ 0, 1 ],
			columns:
				[
					{
					name: "Manufacturer",
					key: "manufacturer",
					sort: (a, b) => b.manufacturer.localeCompare(a.manufacturer, undefined, { caseFirst: "upper" }),
					},
					{
					name: "Name",
					key: "name",
					sort: (a, b) => b.name.localeCompare(a.name, undefined, { caseFirst: "upper" }),
					},
					{
					name: "Stock",
					key: "stock",
					display: Pound,
					},
				],
			};

		const edit_layout = [
			{ name: "Manufacturer", value: "manufacturer", required: true },
			{ name: "Name", value: "name", required: true },
			{ name: "Stock (in pounds)", value: "stock", required: true, load: toPound, save: toGrain },
		];

		if (this.state.powders === null)
			API("/powder").then(data => this.setState({ powders: data.powders }));

		return (
			<>
				<SortTable data={this.state.powders} {...settings} />
				{this.state.powder &&
					<EditDialogue
						layout={edit_layout}
						onHide={this.hide}
						title="Powder"
						item={this.state.powder}
						onSave={this.save}
					/>
				}
			</>
		);
		}
	}

export default PowderContainer;
