import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

import SortTable from '../../component/SortTable';
import EditDialogue from '../../component/EditDialogue';

import API from '../../utils/API';
import { Display as PrimerDisplay } from '../../utils/Primer';
import { Display as PowderDisplay } from '../../utils/Powder';
import { Display as BulletDisplay } from '../../utils/Bullet';
import { Display as ShellDisplay, Sort as ShellSort } from '../../utils/Shell';
import Grain from '../../utils/Grain';

export class LoadContainer extends React.Component
	{
	state =
		{
		loads: null,
		load: null,
		shells: null,
		reload: null,
		qty: null,
		date: null,
		};

	save = load =>
		{
		API(
			{
			url: `/bload/${load.id || ''}`,
			method: load.id ? 'PATCH' : 'PUT',
			body: load,
			})
		.then((data) =>
			{
			this.setState(state =>
				{
				let loads = [ ...state.loads ];
				const i = loads.findIndex(e => e.id === load.id);
				if (i >= 0)
					loads[i] = load;
				else if (!load.id && data.id)
					{
					load.id = data.id;
					loads.push(load);
					}

				return { loads, load: null };
				});
			});
		}

	hide = () => this.setState({ load: null });
	hideReload = () => this.setState({ reload: null });

	reload = () => API(
		{
		url: `/breload/${this.state.reload}`,
		method: 'POST',
		body:
			{
			qty: this.state.qty,
			date: this.state.date,
			},
		})
	.then(data =>
		{
		this.setState(state =>
			{
			const load = state.reload;
			const loads = state.loads?.map?.(l => ({ ...l, used: l.id === load ? true : l.used }));

			return {
				reload: null,
				qty: null,
				date: null,
				loads,
				};
			});
		});

	canReload = () => this.state.qty && this.state.date;

	render()
		{
		var settings =
			{
			header_icons:
				[
					{
					icon: "plus",
					onClick: () =>
						{
						this.setState({ load: {} });
						},
					},
				],
			icons:
				[
					{
					icon: "edit",
					alt: 'Edit',
					hide: load => !!load.used,
					onClick: i =>
						this.setState({ load: { ...this.state.loads[i] } }),
					},
					{
					icon: 'balance-scale',
					alt: 'Reload',
					onClick: i => this.setState({ reload: this.state.loads[i].id })
					},
				],
			sort: [ 0, 1, 2 ],
			group: 0,
			columns:
				[
					{
					name: "Shell",
					key: "shell_id",
					sort: (a, b) => ShellSort(a.shell_id, b.shell_id, this.state.shells),
					display: v => ShellDisplay(this.state.shells?.find?.(s => s.id === v)),
					visible: false,
					},
					{
					name: "Bullet",
					key: "bullet_id",
					display: v => BulletDisplay(this.state.bullets?.find?.(b => b.id === v)),
					},
					{
					name: "Powder",
					key: "powder_id",
					display: (powder, shell) => Grain(shell.powder_weight) + " " + PowderDisplay(this.state.powders?.find?.(p => p.id === powder)),
					},
					{
					name: "Primer",
					key: "primer_id",
					display: v => PrimerDisplay(this.state.primers?.find?.(p => p.id === v)),
					},
				],
			};

		const edit_layout = [
			{ name: "Shell", value: "shell_id", options: this.state.shells, required: true, display: ShellDisplay, save: Number },
			{ name: "Bullet", value: "bullet_id", options: this.state.bullets, required: true, display: BulletDisplay, save: Number },
			[
				{ name: "Powder", value: "powder_weight", append: "gr.", required: true },
				{ name: "", value: "powder_id", options: this.state.powders, required: true, display: PowderDisplay, save: Number },
			],
			{ name: "Primer", value: "primer_id", options: this.state.primers, required: true, display: PrimerDisplay, save: Number },
		];

		if (!this.state.loads)
			API("/bload").then(data => this.setState({ loads: data.loads }));
		if (!this.state.shells)
			API('/shell').then(data => this.setState({ shells: data.shells }));
		if (!this.state.bullets)
			API('/bullet').then(data => this.setState({ bullets: data.bullets }));
		if (!this.state.powders)
			API('/powder').then(data => this.setState({ powders: data.powders }));
		if (!this.state.primers)
			API('/primer').then(data => this.setState({ primers: data.primers }));

		return (
			<>
				<SortTable data={this.state.loads} {...settings} />
				{ this.state.load !== null &&
					<EditDialogue
						layout={edit_layout}
						onHide={this.hide}
						title="Load"
						item={this.state.load}
						onSave={this.save}
					/>
				}
				<Modal show={this.state.reload !== null} onHide={this.hideReload}>
					<Modal.Header closeButton>
						<Modal.Title>Reload Shell</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Form.Group controlId="date">
							<Form.Label>Date</Form.Label>
							<Form.Control type="date" value={this.state.date} onChange={e => this.setState({ date: e.target.value })} />
						</Form.Group>
						<Form.Group controlId="qty">
							<Form.Label>Quantity</Form.Label>
							<Form.Control type="number" value={this.state.qty} onChange={e => this.setState({ qty: e.target.value })} />
						</Form.Group>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="danger" onClick={this.hideReload}>Cancel</Button>
						<Button
							variant="success"
							onClick={this.reload}
							disabled={!this.canReload()}
						>
							Load
						</Button>
					</Modal.Footer>
				</Modal>
			</>
		);
		}
	}

export default LoadContainer;
