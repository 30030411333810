import React from 'react';
import { Modal, Button, Form, InputGroup, Col } from 'react-bootstrap';

export class EditDialogue extends React.Component
	{
	constructor(props)
		{
		super(props);
		this.state =
			{
			item: { ...this.props.item },
			};
		this.renderObject = this.renderObject.bind(this);
		this.renderControl = this.renderControl.bind(this);
		this.onChange = this.onChange.bind(this);
		}

	onChange(e)
		{
		const n = e.target.name;
		let v = e.target.value;
		/* TODO: Don't hack this. */
		if (v === 'Choose one...')
			v = null;
		const obj = this.props.layout.find(p => p.value === n);
		this.setState(state =>
			{
			if (obj?.save)
				v = obj.save(v);
			let item = { ...state.item };
			item[n] = v;
			return { item };
			});
		}

	canSubmit = () =>
		{
		let valid = true;

		this.props.layout.forEach(p =>
			{
			if (!p.required)
				return;

			if (!this.state.item[p.value])
				valid = false;
			});

		return valid;
		};

	renderControl(obj, item)
		{
		let value = item[obj.value];
		if (value && typeof(value) === 'object')
			value = value.value || value.id;
		if (obj.load)
			value = obj.load(value);

		const display = obj.display ? obj.display : o => o.name;

		if (!("options" in obj))
			return <Form.Control required type="text" name={obj.value} value={value} onChange={this.onChange} />;

		return (
			<Form.Control required as="select" name={obj.value} value={value} onChange={this.onChange}>
				<option value={null}>Choose one...</option>
				{ obj.options.map((o, i) => <option value={o.value || o.id} key={i}>{display(o)}</option>) }
			</Form.Control>
		);
		}

	renderObject(obj, item, key)
		{
		return (
			<Form.Group controlId={obj.value} key={key}>
				<Form.Label>{obj.name ? `${obj.name}:` : '\u00A0'}</Form.Label>
				<InputGroup>
					{ this.renderControl(obj, item) }
					{ "append" in obj ? (
						<InputGroup.Append>
							<InputGroup.Text>{obj.append}</InputGroup.Text>
						</InputGroup.Append>
					) : '' }
				</InputGroup>
			</Form.Group>
		);
		}

	render()
		{
		const item = this.state.item || {};
		return (
			<Modal show={this.state.item !== null} onHide={this.props.onHide}>
				<Modal.Header closeButton>
					<Modal.Title>{ this.state.item !== null ? 'Edit' : 'Create' } {this.props.title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{
					this.props.layout.map((line, i) =>
						{
						if (typeof(line) !== 'object')
							return <React.Fragment key={i} />;
						else if (!Array.isArray(line))
							return this.renderObject(line, item, i);
						else
							return (
								<Form.Row key={i}>
									{line.map((sl, j) => <Col>{this.renderObject(sl, item, j)}</Col>)}
								</Form.Row>
							);
						})
					}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="danger" onClick={this.props.onHide}>Cancel</Button>
					<Button
						variant="success"
						onClick={() => this.props.onSave(this.state.item)}
						disabled={!this.canSubmit()}
					>
						Save
					</Button>
				</Modal.Footer>
			</Modal>
		);
		}
	}

export default EditDialogue;
