export const Grain = (grain = 0, round = 0) =>
	Number(grain).toFixed(round) + " gr";

export const GrainWhole = (grain = 0) => Grain(grain, 0);

export const Pound = (grain = 0) =>
	(Number(grain) / 7000).toFixed(2) + "#";

export const GrainFrac = (grain = 0) => Grain(grain, 1);

export const toGrain = pound => Number(pound) * 7000;
export const toPound = grain => (Number(grain) / 7000).toFixed(2);

export default GrainFrac;
