import React from 'react';
import { Table, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './styles.module.scss';

export class SortTable extends React.Component
	{
	constructor(props)
		{
		super(props);

		this.sort = this.sort.bind(this);
		this.state = { hidden: {} };
		}

	sort(a, b)
		{
		if (!("sort" in this.props))
			return;

		let sort = this.props.sort;

		if (typeof(sort) !== "object")
			sort = [ sort ];

		for (let i = 0; i < sort.length; i++)
			{
			let idx = sort[i];
			let col = this.props.columns[Math.abs(idx)];

			let comp = ("sort" in col ? col.sort(a, b) : a[col.key] - b[col.key])
			if (comp)
				return idx > 0 ? comp : -comp;
			}

		return 0;
		}

	render()
		{
		let colCount  = 0;
		let lastGroup = null;
		let icons = this.props.icons || [];
		let header_icons = this.props.header_icons || [];

		if (!this.props.data)
			return <Spinner animation="border" />;

		const data = this.props.data.sort(this.sort);

		return(
			<Table hover size="sm">
				<thead>
					<tr>
						{ icons.length || header_icons.length ?
							<th>
								{
								header_icons.map((icon, i) =>
									{
									if ("hide" in icon && icon.hide)
										return <></>;

									return (
										<FontAwesomeIcon
											icon={icon.icon}
											className={styles.icon}
											onClick={() => icon.onClick && icon.onClick()}
											title={icon.alt}
											key={i}
										/>
									);
									})
								}
							</th>
							:
							""
						}
						{
						this.props.columns.map(column =>
							{
							if (!("display" in column))
								column.display = (v) => v;
							if ("visible" in column && !column.visible)
								return null;
							colCount++;
							return <th>{column.name}</th>;
							})
						}
					</tr>
				</thead>
				<tbody>
					{data.map((row, index) =>
						{
						let thisGroup = null;
						if ("group" in this.props)
							{
							const groupCol = this.props.columns[this.props.group];
							thisGroup = groupCol.display(data[index][groupCol.key], data[index]);
							}
						let x = (
							<>
								{ thisGroup !== lastGroup ?
									<tr className={ `${styles.groupHeader} ${this.state.hidden[thisGroup] ? styles.hidden : ""}` }
										onClick={ e =>
											this.setState(prevState =>
												{
												let hidden = { ...prevState.hidden };
												hidden[thisGroup] = !hidden[thisGroup];
												return { hidden };
												})
											}>
										<td colSpan={ colCount }>{ thisGroup }</td>
									</tr>
								: <></> }
								<tr style={{ display: this.state.hidden[thisGroup] ? "none" : "" }}>
									{ icons.length || header_icons.length ?
										<td>
											{
											icons.map(icon =>
												{
												if ("hide" in icon)
													{
													let hide = icon.hide;
													if (typeof(icon.hide === "function"))
														hide = icon.hide(row);
													if (hide)
														return <></>;
													}

												return <FontAwesomeIcon icon={icon.icon} className={styles.icon} onClick={() => icon.onClick && icon.onClick(index)} title={icon.alt} />;
												})
											}
										</td>
										: ""
									}
								{
								this.props.columns.map(column =>
									{
									if ("visible" in column && !column.visible)
										return null;

									return <td>{ column.display(row[column.key], row) }</td>;
									})
								}
								</tr>
							</>
						);
						lastGroup = thisGroup;
						return x;
						})
					}
				</tbody>
			</Table>
		);
		}
	}

export default SortTable;
