export function Display(primer)
  {
	if (!primer)
		return "";
	
	console.log(primer);

  var str = primer.manufacturer + " " + primer.name;
  if (primer.primer_type.slice(-1) === "M")
    str += " (M)";
  return str;
  }

export function Type(type)
	{
	switch (type)
		{
		case "SP":
			return "Small Pistol";
		case "SPM":
			return "Small Pistol Magnum";
		case "LP":
			return "Large Pistol";
		case "LPM":
			return "Large Pistol Magnum";
		case "SR":
			return "Small Rifle";
		case "SRM":
			return "Small Rifle Magnum";
		case "LR":
			return "Large Rifle";
		case "LRM":
			return "Large Rifle Magnum";
		case "SS":
			return "Shotshell";
		default:
			return "Unknown";
		}
	}

export function SortOrder(primer)
	{
	switch (primer.primer_type)
		{
		case "SP":
			return 1;
		case "SPM":
			return 2;
		case "LP":
			return 3;
		case "LPM":
			return 4;
		case "SR":
			return 5;
		case "SRM":
			return 6;
		case "LR":
			return 7;
		case "LRM":
			return 8;
		case "SS":
			return 9;
		default:
			return 10;
		}
	}

export function Sort(a, b)
	{
	return SortOrder(a) - SortOrder(b)
	}
