import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

import HomeContainer from './container/HomeContainer';
import LoginContainer from './container/LoginContainer';
import BulletContainer from './container/BulletContainer';
import PowderContainer from './container/PowderContainer';
import PrimerContainer from './container/PrimerContainer';
import LoadContainer from './container/LoadContainer';

import API from './utils/API';

library.add(fas);

export class Inv extends React.Component
	{
	constructor(props)
		{
		let user = sessionStorage.getItem("user");

		super(props);
		this.state = { user };
		}

	setUser = user =>
		{
		sessionStorage.setItem("user", user);
		this.setState({ user });
		}

	logout = event =>
		{
		API("/aaa/logout").then(data =>
			{
			sessionStorage.removeItem("user");
			this.setState({ user: null });
			});

		event.preventDefault();
		}

	render()
		{
		let nav_items, user = this.state.user;
		if (user)
			nav_items = (
				<>
					<Nav.Link href="/">Home</Nav.Link>
					<Nav.Link href="bullet">Bullets</Nav.Link>
					<Nav.Link href="powder">Powders</Nav.Link>
					<Nav.Link href="primer">Primers</Nav.Link>
					<Nav.Link href="load">Loads</Nav.Link>
					<Nav.Link href="logout" onClick={this.logout}>Logout</Nav.Link>
				</>
			);
		else
			nav_items = (
				<>
					<Nav.Link href="home">Home</Nav.Link>
					<Nav.Link href="login">Login</Nav.Link>
				</>
			);
	  return (
			<>
				<Navbar bg="light" expand="lg">
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="mr-auto">
							{ nav_items }
						</Nav>
					</Navbar.Collapse>
				</Navbar>
				<Container>
					<Switch>
						<Route path="/bullet" component={BulletContainer} />
						<Route path="/powder" component={PowderContainer} />
						<Route path="/primer" component={PrimerContainer} />
						<Route path="/load" component={LoadContainer} />
						<Route path="/login" render={(props) => <LoginContainer {...props} setUser={this.setUser} />} />
						<Route path="/" component={HomeContainer} />
					</Switch>
				</Container>
			</>
		);
		}
	}

export default Inv;
