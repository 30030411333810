export function Display(shell)
	{
	if (!shell)
		return "";
	return shell.name;
	}

export function Sort(a, b, shells)
	{
	b = shells?.find?.(s => s.id === b);
	if (!b)
		return 0;
	a = shells?.find?.(s => s.id === a);
	if (!a)
		return 0;
	const sizeDiff = b.bullet_caliber - a.bullet_caliber;
	if (sizeDiff)
		return sizeDiff;
	
	return b.name.localeCompare(a.name, undefined, { caseFirst: "upper" });
	}
