import { GrainWhole } from './Grain';

export function Caliber(inch = 0)
	{
	let n = Number(inch).toFixed(3);
	if (inch < 1)
		n = n.substr(1);
	return `${n}"`;
	};

export function BC(bc = 0)
	{
	if (!bc)
		return '';
	
	return Number(bc).toFixed(3);
	};

export function Display(bullet)
	{
	if (!bullet)
		return "";
	return bullet.manufacturer + " " + Caliber(bullet.caliber) + " " + GrainWhole(bullet.weight) + " " + bullet.line + " " + bullet.type;
	}
